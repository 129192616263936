<template>
  <b-form-group
    v-bind="{ ...$props, ...$attrs }"
    v-on="$listeners"
    class="input-suggest-dropdown-component"
  >
    <b-form-input
      v-bind="$attrs"
      v-model="inputValue"
      :class="inputClassName"
      :placeholder="inputPlaceholder"
      :type="inputType"
      @input="handleInput"
      @change="handleChange"
      @focus="handleFocus"
      @blur="handleBlur"
      trim
      autocomplete="off"
    />
    <div
      class="dropdown-list"
      v-if="isSuggestsVisible"
    >
      <div
        class="dropdown-list__item"
        v-for="(suggest, i) in suggests"
        :key="`suggest${i}`"
        @click="handleSuggestClick(suggest)"
      >
        <slot v-bind:suggest="suggest"></slot>
      </div>
    </div>
  </b-form-group>
</template>

<script>
export default {
  name: 'InputSuggestDropdown',
  props: {
    value: {
      default: null,
    },
    isPending: {
      type: Boolean,
      required: true,
    },
    // Ключ по которому возвращать значение из объекта
    returnObjKey: {
      type: String,
      required: true,
    },
    inputClassName: {
      type: String,
      required: false,
      default: null,
    },
    inputPlaceholder: {
      type: String,
      required: false,
      default: '',
    },
    inputType: {
      type: String,
      required: false,
      default: 'text',
    },
    suggests: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data: () => ({
    isSuggestsHidden: true,
  }),
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    isSuggestsVisible() {
      return !this.isSuggestsHidden && (this.suggests.length || !this.isPending);
    },
  },
  methods: {
    handleChange(value) {
      this.$emit('change', value);
    },
    handleInput(value) {
      this.$emit('input', value);
    },
    handleFocus(event) {
      this.$emit('focus', event);
      this.isSuggestsHidden = false;
    },
    handleBlur(event) {
      this.$emit('blur', event);
      // Фикс, которые не позволяет событию blur оказаться впереди
      // события click (клик по подсказке).
      // Иначе dropdown закроется и пользователь не успеет кликнуть по подсказке.
      setTimeout(() => {
        this.isSuggestsHidden = true;
      }, 200);
    },
    handleSuggestClick(valueObject) {
      this.$emit('input', valueObject[this.returnObjKey]);
      this.$emit('select', valueObject[this.returnObjKey], valueObject);
      this.isSuggestsHidden = true;
    },
  },
};
</script>

<style scoped lang="scss">
.input-suggest-dropdown-component {
  position: relative;
  overflow: visible;

  .dropdown-list {
    position: absolute;
    max-height: 200px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ced4da;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 100;

    &__item {
      padding: 0.375rem 0.75rem;
      border-bottom: 1px solid #ced4da;
      cursor: pointer;

      &:last-child {
        border-bottom: none;
      }
    }
  }
}
</style>
