<template>
  <div class="home-view">
    <b-container class="welcome">
      <h5>Вас приветствует <strong>Профи-клуб Бастион</strong></h5>

      <p>
        Здесь можно получить бонусы, профессиональную консультацию и
        расширенную гарантию на оборудование.
      </p>

      <p class="font-weight-bold">
        <router-link to="/devices">
          Количество привязанных устройств: {{ devicesCount }}
        </router-link>
      </p>

      <FillRolesApplicationModal v-if="professionalModalOpen"/>

      <div
          v-if="lastBonus"
          class="my-5"
      >
        <router-link to="/bonuses" >
          <p class="font-weight-bold" >
            Последние изменение баланса:
          </p>
        </router-link>

        <router-link
            to="/bonuses"
            class="home-view__last-bonus"
            v-if="this.getLastBonus"
        >
          <b-card class="carts__item m-0">
            <div class="d-flex flex-row flex-wrap justify-content-between align-items-center">
              <!-- Дата и время операции -->
              <small class="text-secondary">
                {{ getLastBonus.createdAt }}
              </small>

              <!-- Количество -->
              <h5
                  class="mb-0"
                  :class="{
                  'text-primary': getLastBonus.amount >= 0,
                  'text-danger': getLastBonus.amount < 0,
                  }"
              >
                <template v-if="getLastBonus.amount >= 0">+</template>
                {{ getLastBonus.amount }}
                <b-icon
                    icon="lightning-fill"
                    aria-hidden="true"
                    font-scale="1"
                    variant="warning"
                />
              </h5>

              <!-- Описание -->
              <div
                  v-if="getLastBonus.description"
                  class="w-100 mt-2 small"
              >
                {{ getLastBonus.description }}
              </div>
            </div>
          </b-card>
        </router-link>

        <b-alert
            show
            v-else
            variant="info"
        >
          Операции отсутствуют
        </b-alert>
      </div>

      <b-button
          variant="primary"
          to="/bind-device"
          class="mb-3"
      >
        Получить расширенную гарантию
      </b-button>

      <p>
        * Оборудование, произведённое до 01.06.2021 г. имеет штатную гарантию в соответствии
        с паспортом прибора. Активация расширенной гарантии на данное оборудование не требуется.
      </p>

      <p class="font-weight-bold text-primary">
        Как потратить амперчики?
      </p>

      <p>
        Потратить накопленные амперчики вы можете при покупке любого товара в
        <a href="https://skat-ups.ru?utm_source=club.bast.ru" target="_blank">фирменном
          интернет-магазине СКАТ.</a> Для этого при оформлении заказа в поле "Комментарии к
        заказу" укажите какое количество амперчиков вы хотите списать
        (пример "Хочу списать 100 амперчиков").
      </p>

      <p style="margin-bottom: 2.5em">
        <strong>
          Амперчиками можно оплатить до 50% стоимости товара.
        </strong>
      </p>

      <p>
        <span class="text-danger">Важно!</span> Если сумма заказа после применения амперчиков
        менее 5 000 рублей, доставка осуществляется за счет покупателя.
      </p>

      <p>
        <strong>
          При возврате товара потраченные амперчики не возвращаются.<br/>
          Списание амперчиков происходит после оплаты заказа.
        </strong>
      </p>

      <hr>

      <p>
        <strong>БАСТИОН</strong> - российская научно-производственная компания, осуществляющая
        разработку, производство и поставки профессиональных источников бесперебойного питания,
        стабилизаторов, сетевой защиты и др. электротехнического оборудования.
      </p>
    </b-container>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { isProfessionalUser } from '../api/profi_club/professional-user';
import FillRolesApplicationModal from '../components/FillRolesApplicationModal.vue';
import { getProfileBondedDevices } from '../api/profi_club/bonded_devices';

export default {
  name: 'Home',

  components: {
    FillRolesApplicationModal,
  },

  data() {
    return {
      isLoading: true,
      devicesCount: null,
      lastBonus: null,
      isProfessionalUserClub: false,
      professionalModalOpen: false,
    };
  },
  methods: {
    ...mapActions('personalData', {
      updatePersonalData: 'updatePersonalData',
      fetchPersonalData: 'fetchPersonalData',
    }),
    ...mapActions('bonuses', {
      fetchBonuses: 'fetchBonuses',
    }),
    ...mapMutations('personalData', {
      setPersonalData: 'setPersonalData',
    }),
    async professionalTestHandle() {
      if (!this.lastName) {
        await this.fetchPersonalData();
      }
      if (this.isProfessionalUserAuth) {
        const response = await isProfessionalUser();
        this.isProfessionalUserClub = response.isProfessionalUser;
        if (!this.isProfessionalUserClub) {
          this.professionalModalOpen = true;
        }
        return;
      }
      if (Object.keys(this.$route.query).includes('clicked-the-professional-member-button-on-the-registration')) {
        this.setPersonalData({
          isProfessionalUser: true,
          lastName: this.lastName,
          firstName: this.firstName,
          country: this.country,
          email: this.email,
          emailConfirmed: this.emailConfirmed,
          fullProfileData: this.fullProfileData,
          city: this.city,
          phoneNumber: this.phoneNumber,
        });
        await this.updatePersonalData();
        this.professionalModalOpen = true;
      }
    },
  },

  computed: {
    getLastBonus() {
      return (this.bonuses && this.bonuses.operations.length > 0) ? this.bonuses.operations[0]
        : false;
    },

    ...mapGetters('personalData', {
      lastName: 'lastName',
      firstName: 'firstName',
      country: 'country',
      email: 'email',
      emailConfirmed: 'emailConfirmed',
      fullProfileData: 'fullProfileData',
      city: 'city',
      phoneNumber: 'phoneNumber',
      isProfessionalUserAuth: 'isProfessionalUser',
    }),

    ...mapGetters('bonuses', {
      bonuses: 'bonuses',
    }),
  },
  async mounted() {
    await this.professionalTestHandle();
    // Фикс: чтоб вывести кол-во привязанных устройств!
    this.isLoading = true;
    try {
      const resp = await getProfileBondedDevices(1, 10);
      this.devicesCount = resp.count;
      this.isLoading = false;
    } catch (e) {
      this.isLoading = false;
    }

    // Фикс: чтоб вывести последние начисление!
    try {
      if (!this.bonuses) {
        const resp = await this.fetchBonuses();
        if (resp && resp.operations && resp.operations.length > 0) {
          // eslint-disable-next-line prefer-destructuring
          this.lastBonus = resp.operations[0];
        }
      } else if (this.bonuses && this.bonuses.operations && this.bonuses.operations.length > 0) {
        const [firstOperation] = this.bonuses.operations;
        this.lastBonus = firstOperation;
      }
      this.isLoading = false;
    } catch (e) {
      this.isLoading = false;
    }
  },
};
</script>

<style lang="scss">
.home-view {
  .welcome {
    padding: 25px 10px 50px 10px;
  }

  &__last-bonus {
    text-decoration: none;
    color: inherit;
    margin: 0;

    &:visited,
    &:active,
    &:focus,
    &:hover {
      text-decoration: none;
      color: inherit;
    }
  }
}
</style>
