<template>
  <b-modal
      id="modal-1"
      title="Анкета профессионала"
      :no-close-on-backdrop="true"
      :hide-footer="true"
      :visible="professionalModalOpen"
  >
    <b-overlay
        :show="isLoading"
        rounded="sm"
        variant="white"
        opacity="0.85"
        spinner-variant="primary"
    >
      <b-form @submit.prevent>
        <!-- Поле Название юридического лица -->
        <p class="mb-2">
          Название юридического лица/физического лица
        </p>
        <InputSuggestDropdown
            label-for="input-legalEntityName"
            id="input-legalEntityName"
            inputPlaceholder="Начните вводить юр. лицо (min 4 символа)"
            v-model="form.legalEntityName.value"
            :state="form.legalEntityName.state"
            :invalid-feedback="form.legalEntityName.invalidFeedback"
            :isPending="!listLegalEntityIsLoading"
            :suggests="listLegalEntity"
            returnObjKey="value"
            @input="getListLegalEntity"
            @select="selectLegalEntity"
            v-slot="slotData"
        >
          {{ slotData.suggest.value }}<br/>
          <small>ИНН: {{ slotData.suggest.data.inn }}</small><br/>
          <small>{{ slotData.suggest.data.address.unrestricted_value }}</small>
        </InputSuggestDropdown>
        <!-- /Поле Название юридического лица -->

        <!-- Поле ИНН -->
        <p class="mb-2">
          ИНН
        </p>
        <InputSuggestDropdown
            label-for="input-inn"
            id="input-inn"
            inputPlaceholder="Введите ИНН"
            inputType="number"
            v-model="form.inn.value"
            :state="form.inn.state"
            :invalid-feedback="form.inn.invalidFeedback"
            :isPending="!listInnIsLoading"
            :suggests="listInn"
            returnObjKey="value"
            @input="getListInn"
            @select="selectInn"
            v-slot="slotData"
        >
          {{ slotData.suggest.value }}
        </InputSuggestDropdown>
        <!-- /Поле ИНН -->

        <!-- Поле Город -->
        <p class="mb-2">
          Город
        </p>
        <InputSuggestDropdown
            label-for="input-city"
            id="input-city"
            inputPlaceholder="Начните вводить город (min 4 символа)"
            v-model="form.city.value"
            :state="form.city.state"
            :invalid-feedback="form.city.invalidFeedback"
            :isPending="!listCityIsLoading"
            :suggests="listCity"
            returnObjKey="value"
            @input="getListCity"
            v-slot="slotData"
        >
          {{ slotData.suggest.value }}<br/>
        </InputSuggestDropdown>
        <!-- /Поле Город -->

        <!-- Поля Сфер услуг -->
        <div class="mb-3">
          <div class="d-block mb-1">
            Сфера услуг
            <span class="text-danger font-weight-bold">*</span>
          </div>
          <b-form-checkbox-group
              :autofocus="false"
              v-model="form.companyWorkAreas.value"
              :options="companyWorkAreas"
              value-field="item"
              text-field="name"
              disabled-field="notEnabled"
              class="mb-1"
          />

          <b-form-checkbox
              id="is-another"
              v-model="form.isAnotherCompanyWorkAreas.value"
              name="is-another"
              switch
          >
            Добавить другие
          </b-form-checkbox>

          <b-form-tags
              input-id="tags-pills"
              v-model="form.anotherCompanyWorkAreas.value"
              tag-variant="primary"
              tag-pills
              separator=";"
              placeholder="Введите другие сферы деятельности"
              add-button-text="Добавить"
              v-if="form.isAnotherCompanyWorkAreas.value"
              class="mt-1"
          />
        </div>
        <!-- /Поля Сфер услуг -->

        <!-- Поле Сайт организации -->
        <p class="mb-2">
          Сайт организации
        </p>
        <b-form-group
            label-for="input-organizationWebsite"
            :invalid-feedback="form.organizationWebsite.invalidFeedback"
            :state="form.organizationWebsite.state"
        >
          <b-form-input
              id="input-organizationWebsite"
              v-model="form.organizationWebsite.value"
              :state="form.organizationWebsite.state"
              trim
              placeholder="www.domain.com"
          />
        </b-form-group>
        <!-- /Поле Сайт организации -->

        <!-- Поле Должность -->
        <p class="mb-2">
          Должность
        </p>
        <b-form-group
            label-for="input-position"
            :invalid-feedback="form.position.invalidFeedback"
            :state="form.position.state"
        >
          <b-form-input
              id="input-position"
              v-model="form.position.value"
              :state="form.position.state"
              trim
              placeholder="Менеджер"
          />
        </b-form-group>
        <!-- /Поле Должность -->

        <!-- Поле Стаж работы в указанной сфере -->
        <p class="mb-2">
          Стаж работы в указанной сфере
        </p>
        <b-form-group label-for="input-position">
          <b-form-select
              v-model="form.specifiedFieldWorkExperience.value"
              :options="specifiedFieldWorkExperience"
              value-field="item"
              text-field="name"
          />
        </b-form-group>
        <!-- /Поле Стаж работы в указанной сфере -->

        <!-- Ошибка заполнения формы -->
        <b-alert
            show
            v-if="formErrorMessage"
            variant="danger"
        >
          {{ formErrorMessage }}
        </b-alert>
        <!-- /Ошибка заполнения формы -->

        <!-- Ошибка сервера -->
        <b-alert
            show
            v-if="serverErrorMessage"
            variant="danger"
        >
          Ошибка отправки формы
        </b-alert>
        <!-- /Ошибка сервера -->

        <b-button
            type="submit"
            variant="primary"
            block
            :disabled="!formIsValid"
            @click="onSubmitForm"
        >
          Сохранить
        </b-button>

        <!-- Обязательные поля -->
        <p class="mt-2">
          <span class="text-danger font-weight-bold">*</span> - обязательные поля
        </p>
        <!-- /Обязательные поля -->
      </b-form>
    </b-overlay>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import InputSuggestDropdown from './InputSuggestDropdown.vue';
import { getErrorTextFromErResp } from '../api/utils';
import { setProfessionalData } from '../api/profi_club/professional-user';
import { getCompaniesSuggestionByInn, getCompaniesSuggestionByEntityName, getCitiesSuggestion } from '../api/dadata';

const formDefaultData = {
  inn: {
    value: '',
    state: null,
    invalidFeedback: null,
  },
  legalEntityName: {
    value: '',
    state: null,
    invalidFeedback: null,
  },
  city: {
    value: '',
    state: null,
    invalidFeedback: null,
  },
  companyWorkAreas: {
    value: [],
    state: null,
    invalidFeedback: null,
  },
  anotherCompanyWorkAreas: {
    value: [],
    state: null,
    invalidFeedback: null,
  },
  isAnotherCompanyWorkAreas: {
    value: false,
    state: null,
    invalidFeedback: null,
  },
  organizationWebsite: {
    value: '',
    state: null,
    invalidFeedback: null,
  },
  position: {
    value: '',
    state: null,
    invalidFeedback: null,
  },
  specifiedFieldWorkExperience: {
    value: '',
    state: null,
    invalidFeedback: null,
  },
};

export default {
  name: 'FillRolesApplicationModal',

  components: {
    InputSuggestDropdown,
  },

  data() {
    return {
      // Форма
      privacyPolicyText: 'Даю согласие на обработку персональных данных и соглашаюсь с политикой конфиденциальности',
      isLoading: false,
      formErrorMessage: null,
      serverErrorMessage: null,
      form: { ...formDefaultData },
      professionalModalOpen: true,

      // Сферы работы компании
      companyWorkAreas: [
        {
          item: 'Охранно-пожарные системы',
          name: 'Охранно-пожарные системы',
        },
        {
          item: 'Системы отопления',
          name: 'Системы отопления',
        },
        {
          item: 'Электрооборудование',
          name: 'Электрооборудование',
        },
      ],

      // Стаж работы
      specifiedFieldWorkExperience: [
        {
          item: null,
          name: 'Выберите стаж',
        },
        {
          item: 'от 1 до 3 лет',
          name: 'от 1 до 3 лет',
        },
        {
          item: 'от 3 до 5 лет',
          name: 'от 3 до 5 лет',
        },
        {
          item: 'от 5 до 10 лет',
          name: 'от 5 до 10 лет',
        },
        {
          item: 'более 10 лет',
          name: 'более 10 лет',
        },
      ],

      // ИНН
      listInn: [],
      listInnIsLoading: false,

      // Юр. лица
      listLegalEntity: [],
      listLegalEntityIsLoading: false,

      // Города
      listCityIsLoading: false,
      listCity: [],
    };
  },
  methods: {
    async onSubmitForm() {
      this.formErrorMessage = null;

      const legalEntityWorkAreas = this.form.companyWorkAreas.value
        .concat(this.form.anotherCompanyWorkAreas.value).join(';');

      // Отправка на сервер
      this.isLoading = true;
      this.professionalModalOpen = false;
      try {
        await setProfessionalData({
          legalEntityInn: this.form.inn.value,
          legalEntityName: this.form.legalEntityName.value,
          legalEntityCity: this.form.city.value,
          legalEntityWebsite: this.form.organizationWebsite.value,
          position: this.form.position.value,
          workExperience: this.form.specifiedFieldWorkExperience.value,
          legalEntityWorkAreas,
        });
      } catch (e) {
        this.serverErrorMessage = await getErrorTextFromErResp(e);
        this.professionalModalOpen = true;
      } finally {
        this.isLoading = false;
      }
    },

    async getListInn(value) {
      if (!value || value === '' || value.length < 4 || this.listInnIsLoading) return;
      this.listInnIsLoading = true;
      try {
        this.listInn = await getCompaniesSuggestionByInn(value);
        this.listInnIsLoading = false;
      } catch (e) {
        this.listInnIsLoading = false;
        this.listInn = [];
      }
    },
    async getListLegalEntity(value) {
      if (!value || value === '' || value.length < 4 || this.listLegalEntityIsLoading) return;
      try {
        this.listLegalEntity = await getCompaniesSuggestionByEntityName(value);
        this.listLegalEntityIsLoading = false;
      } catch (e) {
        this.listLegalEntityIsLoading = false;
        this.listLegalEntity = [];
      }
    },
    async getListCity(value) {
      if (!value || value === '' || value.length < 4 || this.listCityIsLoading) return;
      try {
        this.listCity = await getCitiesSuggestion(value);
        this.listCityIsLoading = false;
      } catch (e) {
        this.listCityIsLoading = false;
        this.listCity = [];
      }
    },
    async selectInn(valueString, valueObject) {
      this.form.inn.value = valueObject.data.inn;
      this.form.legalEntityName.value = valueObject.data.name.short_with_opf;
      this.form.city.value = valueObject.data.address.data.city;
    },
    async selectLegalEntity(valueString, valueObject) {
      await this.selectInn(valueString, valueObject);
    },
  },

  computed: {
    ...mapGetters('personalData', {
      isProfessionalUser: 'isProfessionalUser',
    }),

    formIsValid() {
      if ((this.form.companyWorkAreas.value.length !== 0
              && this.form.anotherCompanyWorkAreas.value.length === 0)
            || (this.form.companyWorkAreas.value.length === 0
              && this.form.anotherCompanyWorkAreas.value.length !== 0)) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style scoped lang="scss">
.fill-roles-application-data-view {
  max-width: 460px;
  margin: 0 auto;
  padding: 25px 10px 50px 10px;
  text-align: left;
  overflow: visible;
}
</style>
