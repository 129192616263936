const fieldKeys = {
  serialNumber: 'Серийный номер',
};

// Метод который анализирует error response.
// Нужен так как сервер может отдавать ошибки в нескольких форматах.
// Возвращает всегда строку.
export async function getErrorTextFromErResp(error) {
  if (!('data' in error.response)) return error.response;

  if (typeof error.response.data === 'string') {
    return error.response.data.trim();
  }

  if (Object.prototype.toString.call(error.response.data) === '[object Object]') {
    // ESIA API
    if ('error' in error.response.data && typeof error.response.data.error === 'string') {
      return error.response.data.error.trim();
    }

    // Формат DRF
    let erTextRes = '';
    await Object.keys(error.response.data).forEach((erItemKey) => {
      erTextRes += `${fieldKeys[erItemKey]}: `;
      const erItem = error.response.data[erItemKey];

      if (typeof erItem === 'string') {
        erTextRes += erItem;
      }

      if (Array.isArray(erItem)) {
        erTextRes += erItem.join('\n');
      }

      if (Object.prototype.toString.call(erItem) === '[object Object]') {
        erTextRes += getErrorTextFromErResp(erItem);
      }
    });
    return erTextRes.trim();
  }

  // Формат DRF
  if (Array.isArray(error.response.data)) {
    return error.response.data.join('\n').trim();
  }

  // Fallback
  return error.response;
}
