import { axiosProfiClubApiWithCredentials } from '../index';

/* Получить привязанные устройства  */
export async function getProfileBondedDevices(curPage, perPage) {
  const urlSegment = `profile/devices?&curPage=${curPage}&perPage=${perPage}`;
  const response = await axiosProfiClubApiWithCredentials.get(urlSegment);
  if (response.data && response.data.list
    && Array.isArray(response.data.list) && response.data.count) {
    return {
      devices: response.data.list,
      count: response.data.count,
    };
  }
  return {
    devices: [],
    count: 0,
  };
}
/* /Получить привязанные устройства  */
