import { axiosProfiClubApiWithCredentials } from '../index';

export async function isProfessionalUser() {
  const urlSegment = 'profile/professional-user';
  const response = await axiosProfiClubApiWithCredentials.get(urlSegment);
  return response.data ? response.data : null;
}

export async function setProfessionalData(data) {
  const urlSegment = 'profile/professional-user';
  const response = await axiosProfiClubApiWithCredentials.put(urlSegment, data);
  return response;
}
